
	import { defineComponent, reactive, toRefs, ref, onMounted, watch } from 'vue';

export default {
  	name: "Servicos",
  	props: {
		id: {
			type: String,
			required: true,
		},
		servicos: {
            type: Object,
            required: true,
        },
 	},
	
	setup(){
		const Swal = require('sweetalert2');
		let url = ref("");
		let servico = ref("");
		let porquetrocar = ref ("");
		let isObservacaoImage = ref(false);
		
		function fotos(item) {
		if (item) {
			Swal.fire({
			imageUrl: item[4],
			imageWidth: 500,
			imageHeight: 500,
			showCloseButton: true,
			showConfirmButton: false,
			customClass: 'swal-height2'
			});
		}}
		
		async function createFile(item){
			let response = await fetch(item[4]);
				console.log("response da fto", response)
			let data = await response.blob();
			if(!( data.size == 4703 && data.type == "application/octet-stream")){//para retirar as imagens com i
				console.log("if da foto", data);
				return true;
			}else{		
				console.log("else da foto", data);		
				return false;
			}
        }             
        
		

		function videos(item) {
		if (item) {
			Swal.fire({
			html: `<video loop muted autoplay controls="true" width='100%' height='100%' src='${item[4]}' type='video/mp4'>
					      <p style="margin: 0; padding: 0; white-space: nowrap; allowfullscreen">${item[2]}</p>
					   </video>`,
			showCloseButton: true,
			showConfirmButton: false,
			})
		}}

		return{
			url,
			servico,
			fotos,
			videos,
			porquetrocar,
			createFile,
			isObservacaoImage
	
		}
    },
};
