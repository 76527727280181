
	import { defineComponent, reactive, toRefs, ref, onMounted, watch } from 'vue';
	import { getLogEtapa } from "@/services/OficinaDigital";

export default {
  	name: "LogdeVisualizacaoModal",

	props: {
		data: {
			type: Array,
			required: true,
		}
		
 	},
	setup(props){

/* 		console.log("props.dados", props.data) */
		watch(props.data, () => {
	/* 	console.log("props.dados", props.data) */
		})
	


		return{

		};
	}

}
