
	import { ContentLoader } from 'vue-content-loader';
	import { useRouter } from 'vue-router';
	import { hideModal } from '@/core/helpers/dom';
	import {ref,reactive, onMounted,watch, onBeforeUnmount, toRefs, createApp } from 'vue';
	import ServicosAprovadosModal from "./components/ServicosAprovadosModal.vue";
	import ServicosReprovadosModal from "./components/ServicosReprovadosModal.vue";
	import ModalContatosGerenciar from "./components/ModalContatosGerenciar.vue";
	import EdicaoOrcamentosModal from "./components/EdicaoOrcamentosModal.vue";
	import ServicosModal from "./components/ServicosModal.vue";
	import MessageModal from "@/components/modals/geral/MessageModal.vue";
	import LogEtapasModal from "@/components/modals/OficinaDigitalModals/LogEtapasModal.vue";
  	import {getOficinaDigitalGerenciar, buscarPeca, avancarEtapa} from "@/services/OficinaDigital";
	import { enviarMensagem } from "@/services/GeralService";
	import { GerenciarModel } from "@/models/OficinaDigitalModel";
	import useEmitter from '@/composables/Emmiter'
	import App from './App.vue'
	import useAlert from '@/composables/Alert';
	import store from "@/store/index";
	import { getAbrirlink } from "@/services/OficinaDigital";
	import { getvoltarEtapa } from "@/services/OficinaDigital";
	import { getvoltarstatus } from "@/services/OficinaDigital";
	import { setOrcamentoNegociacao } from "@/services/OficinaDigital";
	import EditarOrcamentoModal from "./components/EditarOrcamentoModal.vue";
	import { Actions, Mutations } from "@/store/enums/StoreEnums";
  import axios from "axios";

	export default{
		name: 'OficinaDigitalGerenciarV3',
		components:{
			MessageModal,
			ServicosModal,
			ServicosAprovadosModal,
			ServicosReprovadosModal,
			LogEtapasModal,
			ModalContatosGerenciar,
			EdicaoOrcamentosModal,
			ContentLoader,
			EditarOrcamentoModal
		},
		
		setup(){

			store.commit(Mutations.DELETE_ALL_NOTIFICATION);
			store.dispatch(Actions.ALTER_TITLE);
			let animate = ref(true);
			const emitter = useEmitter();
			const Swal = require('sweetalert2');	
      const { showConfirmAlert, showTimeAlert } = useAlert();
			const openModal = ref(0);

			const router = useRouter();
			
			let orcamentoSelected = ref({});
			var dados:any = reactive({
				info: {},
				servicos: {
					itemReparo:[],
					itemAtencao:[],
					preventivo:[],
					modelo:[],
					
					
				},
				servicosEdicao:[],
				itensAprovados: [],
				itensReprovados: [],
				visualizacoes: [],
				data:{
					modelo:"",
					marca: "",
					telefone: "",
					mensagem: null,
					cliente: "",
					codConcessionaria: 0,
					codChecklist: 0,
					checklist: 0,
					tipo: 0,
					email:""
				},
			})
			var codConcessionaria = ref();
			const modalTelefone = ref(''); //variavel para pegar o telefone do cliente e aparecer no modal
			const clicked = ref(false); //variavel para controlar o botao de enviar para enviando.. (enquanto a requisição é efetuada ao backend)
			const loading = ref(false); 
			const modalEtapa = ref(0);
			const roote = ref();
			var dadosmodal = ref()
			var logEtapas = ref();
			var logReprovados = ref();
			var atualizacoes = ref();
			var negociacao = ref();
			var intervalID = 0;
      const tiposOs = ref<string[]>([]);

			let dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");
			var infoAnterior = ""; //pega a data exata do ultimo orçamento.

			store.watch(() => store.getters.isNotificationGerenciarSocket, async () => {
				console.log("entrou no watch");
				//await getClientesGerenciar();
				await listaClientesGerenciar();
			
			});

			emitter.on("filtrar-od-gerenciar", async data  => {
				animate.value = true; 
				codConcessionaria.value = data.codConcessionaria;			
				await listaClientesGerenciar();		
				animate.value = false; 	 
				if(intervalID){
					clearInterval(intervalID);
				}
				
				intervalID = setInterval(function(){
				/* 	console.log("SetInterval GERENCIAR"); */
					listaClientesGerenciar();		
				},(60000));
				
			});
			emitter.on("atualiza-gerenciar", async data  => {		
				await listaClientesGerenciar();				
			});
			onBeforeUnmount(()=>{
				if(intervalID){
					clearInterval(intervalID);
				}
			});

			function alert(texto, verificador){				
				const icon = (verificador ?'success': 'error');
				const Toast = Swal.mixin({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 3000,
					timerProgressBar: true,
					didOpen: (toast) => {
						toast.addEventListener('mouseenter', Swal.stopTimer)
						toast.addEventListener('mouseleave', Swal.resumeTimer)
					}
				})
				Toast.fire({
					icon: icon,
					background: '$cor_indicadores_1',
					title: texto
				})
			}

			function getAtualizacao(cliente){
				orcamentoSelected.value = Object.assign({}, cliente);
				let servicosAtualizacao = [];
			/* 	console.log("cliente editar", cliente) */
				let servicsoaprovados = cliente.itensAprovados.map(itemAprovado => {
					return { 
						...itemAprovado, 
						situacao : 'aprovado',
						situacaoOnOff : true
					}
				})

				let servicsoReprovados = cliente.itensReprovados.map(itemReprovado => {
					return { 
						...itemReprovado, 
						situacao : 'reprovado',
						situacaoOnOff : false
					}
				})

				dados.servicosEdicao = servicosAtualizacao.concat(servicsoaprovados,servicsoReprovados)			
			};

			function getInformacoes(cliente){
				orcamentoSelected.value = Object.assign({}, cliente);
			 	console.log("orçamentos.value", orcamentoSelected.value) 

				dados.info.placa = cliente.placa;
				dados.info.cliente = cliente.cliente;
				dados.info.modelo = cliente.modelo;
				dados.info.email = cliente.email;
				dados.info.telefone = cliente.telefone;
				dados.info.consultor = cliente.nomeConsultor;
				dados.info.mecanico = cliente.nomeMecanico;
				dados.info.dataReprovacao = cliente.e_data + " " + cliente.e_hora;
			};


			function goTo(url){
				router.push(url)
			};

			async function editaOrcamento(orcamentoSelected, servicoSelected){
			Swal.fire({
				title: "Deseja confirmar a aprovação do serviço?",
				text: "A ação não poderá ser desfeita",
				position: 'center',
				showConfirmButton: true,
				reverseButtons: true,
				allowOutsideClick: false,
				showCancelButton: true,
				customClass: {
					confirmButton: "btn btn-flex background_btn_1_opct text-white",
					cancelButton: "btn btn-light me-3",
				},
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
				focusConfirm: false,
			}).then((result) => {
				if (result.isConfirmed) {
					const postData = {
						codOrcamento: servicoSelected.codOrcamento,
						codConcessionaria: orcamentoSelected.codConcessionaria,
						checklist: orcamentoSelected.checklist, 
						codCheckList: orcamentoSelected.codCheckList,
						aprovacao: 1
					};

					setOrcamentoNegociacao(postData)
					.then(response => {
						showTimeAlert("O serviço foi aprovado com sucesso!");
					})
					.catch(() => {
						showTimeAlert("Não foi possível completar a solicitação", "error");
						servicoSelected.situacaoOnOff = false;
					});
				} else {
					servicoSelected.situacaoOnOff = false;
				}
			});
			};

			async function getLink (codCheckList, checklist, cliente){
				var postData = {
					cliente: cliente,
					checklist: checklist,
					codChecklist: codCheckList,
					codConcessionaria: codConcessionaria.value
				}

				var response = await getAbrirlink(postData);
				//O código setTimeout é executado no thread principal, em vez do assíncrono. Testado no Chrome e Safari.
				setTimeout(() => {
					window.open(response,'_blank');			
				})
			}
			function goToParams(routeName, codCheckList, checklist){
				router.push(
					{
						name: routeName,
						params: {
							codCheckList,
							checklist,
							codConcessionaria: codConcessionaria.value
						}
					}
				);
			};

			async function voltarEtapa(codCheckList, checklist){
				Swal.fire({
					title: "Tem certeza que deseja voltar etapa?",
					text: " Este orçamento irá retornar para a etapa 2 e você perderá o link que foi enviado para o cliente e essa ação não poderá ser desfeita.",
					position: 'center',
					showConfirmButton: true,
					reverseButtons: true,
					allowOutsideClick: false,
					showCancelButton: true,
					customClass: {
						confirmButton: "btn btn-flex background_btn_1_opct text-white",
						cancelButton: "btn btn-light me-3",
					},
					confirmButtonText: "Sim",
					cancelButtonText: "Não",
					focusConfirm: false,
				}).then(async (result) => {
				if (result.isConfirmed) {
				const voltar = await getvoltarEtapa(codCheckList, codConcessionaria.value, checklist)
					if (voltar == 'success'){
						showTimeAlert("Orçamento retornou para a etapa 2 - Criado.");
						getClientesGerenciar()
					}else{
						showTimeAlert("Não foi possível completar a solicitação", "error");
					}
					console.log("voltar etapa", voltar)
				}});
			}

			async function voltarstatus(codCheckList, checklist){
				Swal.fire({
					title: "Tem certeza que deseja voltar etapa?",
					text: " Este orçamento irá retornar para a etapa mecânico, essa ação não poderá ser desfeita.",
					position: 'center',
					showConfirmButton: true,
					reverseButtons: true,
					allowOutsideClick: false,
					showCancelButton: true,
					customClass: {
						confirmButton: "btn btn-flex background_btn_1_opct text-white",
						cancelButton: "btn btn-light me-3",
					},
					confirmButtonText: "Sim",
					cancelButtonText: "Não",
					focusConfirm: false,
				}).then(async (result) => {
				if (result.isConfirmed) {
				const voltar = await getvoltarstatus(codCheckList, codConcessionaria.value, checklist)
					if (voltar == 'success'){
						showTimeAlert("Solicitação concluída com sucesso!");
						getClientesGerenciar()
					}else{
						showTimeAlert("Não foi possível completar a solicitação", "error");
					}
					console.log("voltar etapa", voltar)
				}});
			}
			
			

			

			async function getClientesGerenciar(){
				animate.value = true;
				await listaClientesGerenciar();
				animate.value = false;
			}

			async function listaClientesGerenciar(){
				var response = await getOficinaDigitalGerenciar(codConcessionaria.value);
				dados.info = response;
        tiposOs.value = response.tipoOSAbertura ? response.tipoOSAbertura.split(",") : [];
			}

			function trataNome(nome){
				if(!nome || nome == "undefined" || nome == undefined){
					return "";
				}
				const arrayNomes = nome.split(" ");

				let nomeTratado = `${arrayNomes[0]}`;
				if(arrayNomes[1]){
					nomeTratado += ` ${arrayNomes[1].slice(0, 0)}.`;
				}

				return nomeTratado;
			};	

			function getContatos (contatos){
				const teste = JSON.parse(JSON.stringify(contatos))
				dados = JSON.parse(JSON.stringify(teste.contatos));
			/* 	console.log("data modal", dados) */
			}

			function getAprovados(cliente){   //passa os itens aprovados por um unico cliente
				const teste = JSON.parse(JSON.stringify(cliente))
				dados.itensAprovados = teste.itensAprovados;
			/* 	console.log("itens aprovados", dados.itensAprovados)
				console.log("cliente: ", cliente);
				console.log(teste.itensAprovados); */
			}			

			function getReprovados(cliente){   //passa os itens reprovados por um unico cliente
				const teste = JSON.parse(JSON.stringify(cliente))
				dados.itensReprovados = JSON.parse(JSON.stringify(teste.itensReprovados));
			/* 	console.log("cliente: ", cliente);
				console.log(dados.itensReprovados); */
			}

			function getVisualizacoes(cliente){ //passa o log de visualizacoes para a variavel reativa 
				const teste = JSON.parse(JSON.stringify(cliente));
				dados.visualizacoes = teste.visualizacao;
			}

			function getServicos(cliente){   //passa os servicos de um unico cliente
				const teste = JSON.parse(JSON.stringify(cliente))
				dados.servicos.itemReparo = teste.itemReparo;
				dados.servicos.itemAtencao = teste.itemAtencao;
				dados.servicos.preventivo = teste.preventivo;		

			}

			function mascaraTelefone(telefone){
				let arrayMascaras = " ";
				if(telefone){
					let replaceTelefone = telefone.replaceAll(" ", ""); //retira espacos do telefone ex: (31)33333333(31)222222222
					replaceTelefone = replaceTelefone.replaceAll(")", ""); //retira parenteses ex: (3133333333(3122222222
					let splitTelefone = replaceTelefone.split("("); //Cria array separando pelo parenteses ex: ["","3133333333","3122222222"]
					let arrayTelefones = splitTelefone.filter((a)=>a); //Cria filtro para retirar null e vazio ex: ["3133333333","3122222222"]
					arrayTelefones.forEach((value, index)=>{  //percorre o array de telefones
						let mascara = "("+value.substring(0,2)+") ****-"+value.substring(value.length-4,value.length);
						arrayMascaras = arrayMascaras +" "+ mascara;
					});	
				}
				return arrayMascaras;
			}

			function selecionaNumero(telefone){//caso exista mais de um telefone, escolhe a melhor opção para wpp e sms
				let telefoneSelecionado = '';					
			/* 	console.log(telefone); */
				if(telefone){
					let replaceTelefone = telefone.replaceAll(" ", ""); //retira espacos do telefone ex: (31)33333333(31)222222222
					replaceTelefone = replaceTelefone.replaceAll(")", ""); //retira parenteses ex: (3133333333(3122222222
					let splitTelefone = replaceTelefone.split("("); //Cria array separando pelo parenteses ex: ["","3133333333","3122222222"]
					let arrayTelefones = splitTelefone.filter((a)=>a); //Cria filtro para retirar null e vazio ex: ["3133333333","3122222222"
					if(arrayTelefones.length > 1){  //Verifica se existe mais de um numero de telefone
						/* console.log("arrayTelefones", arrayTelefones); */
						arrayTelefones.forEach((value, index)=>{  //percorre o array de telefones
							if(value.length==11){  //verifica se tem telefone com 11 digitos (se tem o nono digito que provavelmente é um celular)
								telefoneSelecionado = value;   //seleciona o telefone que provavelmente é um celular (contem wpp).
							}
						});
					}else{
						telefoneSelecionado = arrayTelefones[0];  //se for apenas um telefone, seleciona
					}
				}else{
					telefoneSelecionado="";
				}
				dados.data.telefone=telefoneSelecionado; 
				modalTelefone.value = telefoneSelecionado;  
			}

			async function getTelefone(cliente, tipo){//passa os dados do cliente ATUAL para o array para que quando clicado em enviar, chamar a funcao post
				modalTelefone.value = "";
				modalEtapa.value = cliente.etapa;
				dados.data.marca = cliente.marca;
				dados.data.cliente= cliente.cliente;
				/* console.log("cliente gerenciar",cliente) */
				dados.data.codConcessionaria=cliente.codConcessionaria;
				dados.data.codChecklist=cliente.codChecklist;
				dados.data.checklist= cliente.checklist;
				dados.data.tipo = tipo;
				dados.data.email = cliente.email
				dados.data.mensagem = null;

				if(cliente.telefone){
					dados.data.telefone = cliente.telefone;
					selecionaNumero(cliente.telefone);
				}else{
					dados.data.telefone = "";
				}
			}
		
			async function sms(tipo = 0){
				if(!clicked.value){
					clicked.value = true;
					dados.data.telefone=modalTelefone.value;
					dados.data.tipo = tipo;

					let postData = Object.assign({}, dados.data);
					if(tipo == 2 || tipo == 3){
						postData.mensagem = null;
					}
					
					await enviarMensagem(postData)
						.then((value)=>{
							tipo != 1 ? window.open(value, '_blank') : alert(value, true);
							const elementModal = document.getElementById("kt_modal_2");
							hideModal(elementModal);
							getClientesGerenciar();	
							/* hideModal(elementModal2); */
						})
						.catch((error)=>{
							alert(error.response.data.telefone ?? "Não foi possível enviar a notificação", false);
						}).finally(() => {
							clicked.value = false;
						});	
				/* 	console.log(response);	 */	
					/* 	const elementModal2 = document.getElementById("kt_modal_3"); */
					
				}
			}

			async function wpp(tipo = 0){
				if(!clicked.value){
				clicked.value = true;
				dados.data.telefone=modalTelefone.value;
				dados.data.tipo = tipo;

				let postData = Object.assign({}, dados.data);
				if(tipo == 2 || tipo == 3){
					postData.mensagem = null;
				}
				
				const response = await enviarMensagem(postData)
				.then((value)=>{
					tipo!=1?window.open(value, '_blank'):alert(value, true);
				 	
					/* hideModal(elementModal2); */
				})
				.catch((error)=>{
					alert(error.response.data.telefone, false);
				});	
			/* 	console.log(response);	 */	
				clicked.value = false;
				const elementModal2 = document.getElementById("kt_modal_3"); 
               	hideModal(elementModal2);
				await getClientesGerenciar(); 
				}
			}
			const textoPeca = ref("");
			const opcaoPeca = ref("");
			const tipoPeca = ref("");
			const pecas:any = ref([]);
			const loadingButtonModalPeca = ref(false);
			const resultadoNaoEncontrado = ref(false);
			let page = ref(1);
			const tipoMaoObra = ref("");
			const tipoOsBusca = ref("");
			const totalItens = ref(0);
			
			function buscarPecas() {
        loadingButtonModalPeca.value = true;

        const postData = {
          marca: dados.info.marca,
          cnpj: dados.info.cnpj,
          texto: textoPeca.value,
          tipo: tipoPeca.value,
          opcao: opcaoPeca.value,
          dms: dados.info.dms,
          urlIntegracao: dados.info.urlIntegracao,
          tipoOs: tipoOsBusca.value,
        };

        const separar = (itens, maximo) => {
          return itens.reduce((acumulador, item, indice) => {
            const grupo = Math.floor(indice / maximo);
            acumulador[grupo] = [...(acumulador[grupo] || []), item];
            return acumulador;
          }, []);
        };

        buscarPeca(postData).then(function (response) {
          if (Array.isArray(response) && response.length > 0) {
            totalItens.value = response.length;
            resultadoNaoEncontrado.value = false;
            pecas.value = separar(response, 20);
          } else {
            resultadoNaoEncontrado.value = true;
            pecas.value = [];
          }
          
          response.forEach((peca) => {
              if (peca.estoque) {
                peca.estoque.replace('.', '');
                peca.estoque = parseFloat(peca.estoque).toFixed(0);
              } else {
                peca.estoque = 0;
              }
              if (peca.valor) {
                peca.valor = parseFloat(peca.valor).toLocaleString('pt-Br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2
                });
              } else {
                peca.valor = "Sem info";
              }

              if (peca.tempo) {
                peca.tempo = parseFloat(peca.tempo).toFixed(2);
              } else {
                peca.tempo = "Sem info";
              }
            });
          
        }).catch(function (error) {})
          .finally(() => {
            loadingButtonModalPeca.value = false;
          });
      }

      function resetModal() {
        pecas.value = [];
        textoPeca.value = "";
        opcaoPeca.value = "";
        resultadoNaoEncontrado.value = false;
      }

      function setPage(val) {
        page.value = val;
      }
      
      const loadingButtonAvancar = ref(false);
      const loadingButtonEnviarDms = ref(false);

      function avancarEtapaGerenciar(item) {
        const postData = {
          codCheckList: Number(item.codChecklist),
          codConcessionaria: Number(item.codConcessionaria),
          checklist: Number(item.checklist)
        }
        Swal.fire({
          title: "Deseja avançar o orçamento?",
          text: "A ação não poderá ser desfeita",
          position: 'center',
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: true,
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-flex background_btn_1_opct text-white",
            cancelButton: "btn btn-light me-3",
          },
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          focusConfirm: false,
        }).then((result) => {
          if (result.isConfirmed) {
            loadingButtonAvancar.value = true;
            avancarEtapa(postData).then((value) => {
              loadingButtonAvancar.value = false;
              showTimeAlert(value);
              listaClientesGerenciar();
            }).catch((error) => {
              showTimeAlert(error, 'error');
            });
          }
        });
      }

      async function enviarOrcamentoDms(checklist, codChecklist, codConcessionaria) {
      Swal.fire({
        title: "Deseja enviar orçamento para o DMS?",
        text: "A ação não poderá ser desfeita",
        position: "center",
        showConfirmButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-flex background_btn_1_opct text-white",
          cancelButton: "btn btn-light me-3",
        },
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          loadingButtonEnviarDms.value = true;
          axios.post('/gerarOrcamento', {
            checklist,
            codChecklist,
            codConcessionaria
          }, {
            headers: {
              'content-type': 'application/json',
              'token': 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'
            }
          }).then(response => {
            loadingButtonEnviarDms.value = false;
            showConfirmAlert(response.data);
            getClientesGerenciar();
          }).catch((e) => {
            loadingButtonEnviarDms.value = false;
            showConfirmAlert(e.response.data, "error");
          });
        }
      });
    }
      
			return {
				...toRefs(dados),
				goToParams,
				alert,
				clicked,
				loading,
				sms,
				getTelefone,
				modalTelefone,
				mascaraTelefone,
				modalEtapa,
				getServicos,
				getAprovados,
				getReprovados,
				getVisualizacoes,
				goTo,
				codConcessionaria,
				getContatos,
				roote,
				logEtapas,
				logReprovados,
				negociacao,
				atualizacoes,
				dominio_orcamento,
				getClientesGerenciar,
				openModal,
				animate,
				trataNome,
				getLink,
				editaOrcamento,
				getAtualizacao,
				orcamentoSelected,
				getInformacoes,
				wpp,
				voltarEtapa,
				voltarstatus,
				opcaoPeca,
				textoPeca,
				loadingButtonModalPeca,
				resultadoNaoEncontrado,
				pecas,
				buscarPecas,
				setPage,
				page,
				tipoPeca,
				tipoMaoObra,
        tipoOsBusca,
				avancarEtapaGerenciar,
				loadingButtonAvancar,
				totalItens,
				resetModal,
        enviarOrcamentoDms,
        loadingButtonEnviarDms,
        tiposOs
			};
		}
	}
