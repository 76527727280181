
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from "vue";

export default {
  name: "ServicosAprovados",
  props: {
    id: {
      type: String,
      required: true,
    },
    itensAprovados: {
      type: Object,
      required: true,
    },
  },
};
